<template>
    <div>
        <div class="card card-custom mt-5">
            <div class="card-body">
                <!-- Filter -->
                <div>
                    <div class="m-form m-form--fit m--margin-bottom-20">
                        <div class="row">
                            <div class="col-12 mt-10">
                                <table class="table table-bordered">

                                    <tbody>
                                    <tr>
                                        <td>
                                            <h4>{{$t('MENU.accounting_reports')}}</h4>
                                        </td>
                                    </tr>
                                    <tr v-if="$can('report_accounting.report_journal_transactions')">
                                        <td class="d-flex justify-content-between">
                                            <span>{{$t('MENU.journal_transactions_reports')}}</span>
                                            <div>
                                                <router-link :to="{name: 'journal-transactions.list'}" target="_blank">
                                                    {{$t('view')}}
                                                </router-link>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr v-if="$can('report_accounting.report_profit_loss')">
                                        <td class="d-flex justify-content-between">
                                            <span>{{$t('MENU.profit_loss_report')}}</span>
                                            <div>
                                                <router-link :to="{name: 'profit-loss-report.list'}" target="_blank">
                                                    {{$t('view')}}
                                                </router-link>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr v-if="$can('report_accounting.report_income')">
                                        <td  class="d-flex justify-content-between">
                                            <span>{{$t('MENU.income_report')}}</span>
                                            <div>
                                                <router-link :to="{name: 'income-report.list'}" target="_blank">
                                                    {{$t('view')}}
                                                </router-link>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr v-if="$can('report_accounting.report_expense')">
                                        <td  class="d-flex justify-content-between">
                                            <span>{{$t('MENU.expenses_report')}}</span>
                                            <div>
                                                <router-link :to="{name: 'expenses-report.list'}" target="_blank">
                                                    {{$t('view')}}
                                                </router-link>
                                            </div>
                                        </td>
                                    </tr>

                                    <tr v-if="$can('report_accounting.report_balance_sheet')">
                                        <td class="d-flex justify-content-between">
                                            <span>{{$t('MENU.balance_sheet')}}</span>
                                            <div>
                                                <router-link :to="{name: 'balance-sheet.list'}" target="_blank">
                                                    {{$t('view')}}
                                                </router-link>
                                            </div>
                                        </td>
                                    </tr>

                                    <tr v-if="$can('report_accounting.report_chart_accounts')">
                                        <td class="d-flex justify-content-between">
                                            <span>{{$t('MENU.chart_accounts_directory')}}</span>
                                            <div>
                                                <router-link :to="{name: 'chart-accounts-directory.list'}" target="_blank">
                                                    {{$t('view')}}
                                                </router-link>
                                            </div>
                                        </td>
                                    </tr>

                                    <tr v-if="$can('report_accounting.report_account_movements')">
                                        <td class="d-flex justify-content-between">
                                            <span>{{$t('MENU.balance_balances_review')}}</span>
                                            <div>
                                                <router-link :to="{name: 'balance-balances-review.list'}" target="_blank">
                                                    {{$t('view')}}
                                                </router-link>
                                            </div>
                                        </td>
                                    </tr>

                                    <tr v-if="$can('report_accounting.report_tax')">
                                        <td class="d-flex justify-content-between">
                                            <span>{{$t('MENU.tax_report')}}</span>
                                            <div>
                                                <router-link :to="{name: 'tax-report.list'}" target="_blank">
                                                    {{$t('view')}}
                                                </router-link>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr v-if="$can('report_accounting.report_income_expenses')">
                                        <td class="d-flex justify-content-between">
                                            <span>{{$t('MENU.income_expenses_report')}}</span>
                                            <div>
                                                <router-link :to="{name: 'income-expenses-report.list'}" target="_blank">
                                                    {{$t('view')}}
                                                </router-link>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr v-if="$can('report_accounting.report_tax_declaration')">
<!--                                    <tr >-->
                                        <td class="d-flex justify-content-between">
                                            <span>{{$t('MENU.tax_declaration_report')}}</span>
                                            <div>
                                                <router-link :to="{name: 'tax-declaration-report.list'}" target="_blank">
                                                    {{$t('view')}}
                                                </router-link>
                                            </div>
                                        </td>
                                    </tr>

                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";

    export default {
        mounted() {
            this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.accounting_reports")}]);
        },
    }
</script>
<style scoped>
    td {
        padding: 1rem;
    }
</style>